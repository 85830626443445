import { FindSolutionsDesktop } from '@/components/footer/FindSolutionsDesktop';
import { FindSolutionsMobile } from '@/components/footer/FindSolutionsMobile';
import { AolCourses } from '@/components/home/AolCourses';
import ChannelReview from '@/components/home/ChannelReview';
import GetStarted from '@/components/home/GetStarted';
import GurudevSection from '@/components/home/GurudevSection';
import HeroSlider from '@/components/home/HeroSlider';
import Reviews from '@/components/home/Reviews';
import ServiceProjects from '@/components/home/ServiceProjects';
import Loader from '@/components/Loader';
import CountdownTimer from '@components/home/HomeCountdown';
import { useScreenWidth } from '@/components/ScreenWidthProvider';
import { Meta } from '@/layouts/Meta';
import { Main } from '@/templates/Main';

const Index = () => {
  const screenWidth = useScreenWidth();

  return (
    <Main
      meta={
        <Meta
          title="Discover the effective approach to meditation through breath."
          description="Discover Inner Peace and Personal Transformation with The Art of Living, a Global Leader in Meditation and Well-Being Programs."
          image="https://www.artofliving.org/sites/all/themes/unity2/img/artofliving_og_logo.jpg"
          site_name="https://www.artofliving.org/us-en"
        />
      }
    >
      {(screenWidth && (
        <>
          <div>
            <GetStarted />
          </div>
          <HeroSlider />
          <div className="bg-gradient-to-b from-white via-[#FFF7E3] to-white">
            <GurudevSection />
            <AolCourses />
          </div>
          <Reviews />
          <div className="mt-[-100px]">
            <ChannelReview />
          </div>
          <ServiceProjects />
          {screenWidth > 768 ? (
            <FindSolutionsDesktop />
          ) : (
            <FindSolutionsMobile />
          )}
        </>
      )) || <Loader />}
    </Main>
  );
};

export default Index;
